const socialLinks = [
  "https://github.com/nelson-oliveira-sousa",
  "https://www.linkedin.com/in/nelson-de-oliveira-sousa-4721aa102/",
  "https://wa.me/5514998479848",
  "https://www.instagram.com/n.oliveirasousa/",
  "https://www.facebook.com/nelson.deoliveirasousa/",
];
const profileData = {
  userName: "Nelson",
  photoLink: "https://instagram.fudi1-2.fna.fbcdn.net/v/t51.2885-19/15803282_1838137076467332_3306905486825095168_a.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fudi1-2.fna.fbcdn.net&_nc_cat=100&_nc_ohc=Ne9ZCovxr1YAX8XkM4w&tn=pSpiXGflem2lNrRT&edm=ACWDqb8BAAAA&ccb=7-5&oh=00_AfAxMM0luyrPmHVJVjjl19FsntDvICvRE8dvhDriaGNzLQ&oe=63D04D62&_nc_sid=1527a3"
};

const themeData = {
  light: {
    backgroundColor: "white",
    onHoverBackgroundColor: "#dfe6e9",
    cardBackgroundColor: "#ffffff",
    onHoverTextColor: "#636e72",
    footerColor: "black",
    footerSocialLinkColor: "white",
    headerFontColor: "#black",
    CardtextColor: "#2d3436",
  },
  dark: {
    backgroundColor: "#000000",
    onHoverBackgroundColor: "#02040a",
    cardBackgroundColor: "#222222",
    onHoverTextColor: "white",
    footerColor: "white",
    footerSocialLinkColor: "white",
    headerFontColor: "white",
    CardtextColor: "white",
  },
};
const linkData = [
  {
    id: 1,
    link: "https://github.com/nelson-oliveira-sousa",
    name: "GitHub",
  },
  {
    id: 2,
    link: "https://www.linkedin.com/in/nelson-de-oliveira-sousa-4721aa102/",
    name: "Linkedin",
  },
  {
    id: 3,
    link: "https://wa.me/551499847984",
    name: "Whatsapp",
  },
  {
    id: 4,
    link: "https://www.instagram.com/n.oliveirasousa/",
    name: "Instagram",
  },
  {
    id: 5,
    link: "https://www.facebook.com/nelson.deoliveirasousa/",
    name: "Facebook",
  },
];

export { socialLinks, profileData, linkData, themeData };
